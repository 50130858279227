import React, { useEffect, useState } from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"
import '../../styles/custome.css';
import axios from "axios"


export default function PackageGuide() {
  const ignoreList = ["Add 50hrs of DVR", "Pick 5 Channels", "Single Channels"]
  const [plans, setPlans] = useState([]);
  const [addons, setAddons] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const plansResponse = await axios(
          "https://product-catalog-storage.s3.ca-central-1.amazonaws.com/snapshots/latest/services/tv/plans.json"
        );
        setPlans(plansResponse.data);
        const addonsResponse = await axios(
          "https://product-catalog-storage.s3.ca-central-1.amazonaws.com/snapshots/latest/services/tv/addons.json"
        );
        setAddons(addonsResponse.data);
      } catch (err) {
        console.error("Failed to fetch tv plans and/or addons:", err);
      }
    };
    fetchData();

  }, []);

  return (
    <LayoutNew>
      <Helmet>
        <title>Package Guide – Purple Cow Internet 💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg" style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
      }}>
        <h1>TV Package Guide</h1>
        <h2 className="font-bold">Base Packages</h2>
        {plans.map((plan) => (
          <div className={"table-container"}>
            <h3 className="text-2xl font-bold">{plan.name} - ${plan.billing_period[0].monthly.price}</h3>
            <table>
              <thead>
              <tr>
                <th>Channel #</th>
                <th>Channel Name</th>
              </tr>
              </thead>
              <tbody>
              {plan.included_channels.map((channel, index) => (
                <tr key={index}>
                  <td>{channel.channel_number}</td>
                  <td>{channel.name}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        ))}
        <h2 className="font-bold">Extra Packages</h2>
        {addons.map((addon) => (
          ignoreList.includes(addon.name) ? null : (
          <div className={"table-container"}>
            <h3 className="text-2xl font-bold">{addon.name} - ${addon.billing_period[0].monthly.price}</h3>
            <table>
              <thead>
              <tr>
                <th>Channel #</th>
                <th>Channel Name</th>
              </tr>
              </thead>
              <tbody>
              {addon.included_channels.map((channel, index) => (
                <tr key={index}>
                  <td>{channel.channel_number}</td>
                  <td>{channel.name}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
          )
        ))}
      </section>
      <p style={{ textAlign: "center", color: "#666", marginTop: "10px", marginBottom: "20px" }}>
        Last Updated: June 11th 2024
      </p>
    </LayoutNew>
  )
}
